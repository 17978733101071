import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const ChallengeComponent = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  background-color: ${props => props.theme.colors.offWhite};
  padding: 4rem 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .container-padding {
    padding: 4rem 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 7rem 12rem;
    }
  }

  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
  }

  .challenge-title {
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 4rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
      font-size: 6.5rem;
      line-height: 80px;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
  .gold-text-content {
    font-size: 2.3rem;
    line-height: 37px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }

    &--mb3 {
      margin-bottom: 3rem;
    }
  }

  .purple-text-content {
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${props => props.theme.colors.darkerPurple};
    margin-bottom: 3.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-style: ${props => props.theme.fonts.medium};
      font-weight: 500;
      font-size: 3.1rem;
      line-height: 38px;
    }
  }

  .black-text-content {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};

    &--mb2 {
      margin-bottom: 2rem;
    }

    &--mb3 {
      margin-bottom: 3rem;
    }

    &--mb6 {
      margin-bottom: 6rem;
    }
  }
`;
const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyChallenge = props => {
  return (
    <ChallengeComponent id={props.id || "challenge"}>
      <CaseStudyContainer>
        <h2 className="challenge-title">The Challenge</h2>
        <TitleLine />
        <div className="sectionWrapper">
          <div className="leftWrapper">
            <Descriptor title="strategy" color="#D38B5C" alignment="center" />
            <DescriptorImage image={props.ChallengeDescriptor} />
          </div>
          <div className="contentContainer">
            <p className="gold-text-content gold-text-content--mb2">
              From our research, we understood the impact large social and
              economic changes were having on the post-secondary industry.
              Within the industry, competitors were treating students as a
              replenishable commodity. Their approach was stale and offered no
              value to students.
            </p>
            <p className="black-text-content black-text-content--mb6">
              Robertson College needed to reposition their brand by
              contextualizing a changing workforce for students and redefining
              the private career college category. By doing so, Robertson
              College would position their college as the leader and raise up
              the industry by prioritizing the student experience. Focusing on
              the student experience would ultimately solve the problems
              Robertson initially approached us with, too.
            </p>
            <Image
              css={css`
                margin-bottom: 5rem;
              `}
              image={props.ChallengeStrategy}
            />
            <h3 className="purple-text-content purple-text-content--mb3.5">
              Our brand strategy would need to:
            </h3>
            <h4 className="gold-text-content gold-text-content--mb3">
              Redefine and raise the profile of the private career college
              category
            </h4>
            <p className="black-text-content black-text-content--mb3">
              Robertson College needed to create a new category that stands for
              everything that their competitors were not. By doing so, Robertson
              College would reposition themselves as the industry leader and
              automatically differentiate themselves from their competitors.
            </p>
            <h4 className="gold-text-content gold-text-content--mb3">
              Elevate student experience
            </h4>
            <p className="black-text-content black-text-content--mb3">
              Students value a positive and meaningful school experience that
              works to fit with their lifestyle. By prioritizing students’ needs
              and expectations, Robertson College can add value to their
              offerings by creating an experience that matches their audience’s
              expectations.
            </p>
            <h4 className="gold-text-content gold-text-content--mb3">
              Communicate value vs. services
            </h4>
            <p className="black-text-content black-text-content--mb3">
              A laundry list of services isn’t helpful. By focusing on
              communicating the value of their programs, Robertson College will
              immediately differentiate themselves from their competition and
              provide much-needed context for students trying to navigate
              career-focused programming.
            </p>
          </div>
        </div>
      </CaseStudyContainer>
    </ChallengeComponent>
  );
};

export default CaseStudyChallenge;
