import React, { useState } from "react";
import Layout from "../../components/layout";
import CaseStudyOverview from "../../components/case-study/CaseStudyOverview";
import CaseStudyInsights from "../../components/case-study/CaseStudyInsights";
import CaseStudyChallenge from "../../components/case-study/CaseStudyChallenge";
import CaseStudyStrategy from "../../components/case-study/CaseStudyStrategy";
import CaseStudyExecution from "../../components/case-study/CaseStudyExecution";
import CaseStudyResults from "../../components/case-study/CaseStudyResults";
import CaseStudyNavigation from "../../components/case-study-general/CaseStudyNavigation";
import { ContentProvider } from "../../../contexts/ContentContext";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../../components/Hero";
import OverviewDescriptor from "../../icons/case-studies/rc/OverviewDescriptor.svg";
import OverviewBody from "../../icons/case-studies/rc/OverviewBody.svg";
import OverviewLeads from "../../icons/case-studies/rc/OverviewLeads.svg";
import OverviewCollege from "../../icons/case-studies/rc/OverviewCollege.svg";
import OverviewCollegePurple from "../../icons/case-studies/rc/OverviewCollegePurple.svg";
import InsightEducation from "../../icons/case-studies/rc/InsightEducation.svg";
import InsightDescriptor from "../../icons/case-studies/rc/InsightDescriptor.svg";
import InsightWorkforce from "../../icons/case-studies/rc/InsightWorkforce.svg";
import Percent22 from "../../icons/case-studies/rc/Percent22.svg";
import Percent50 from "../../icons/case-studies/rc/Percent50.svg";
import Percent70 from "../../icons/case-studies/rc/Percent70.svg";
import Percent75 from "../../icons/case-studies/rc/Percent75.svg";
import LearnerNewLearner from "../../icons/case-studies/rc/LearnerNewLearner.svg";
import LearnerReLearner from "../../icons/case-studies/rc/LearnerReLearner.svg";
import LearnerCareerShifter from "../../icons/case-studies/rc/LearnerCareerShifter.svg";
import InternationalStudents from "../../icons/case-studies/rc/InternationalStudents.svg";
import ChallengeDescriptor from "../../icons/case-studies/rc/ChallengeDescriptor.svg";
import ChallengeStrategy from "../../icons/case-studies/rc/ChallengeStrategy.svg";
import StrategyDescriptor from "../../icons/case-studies/rc/StrategyDescriptor.svg";
import StrategyGoalGetters from "../../icons/case-studies/rc/StrategyGoalGetters.svg";
import PersonalityKnowledgeable from "../../icons/case-studies/rc/PersonalityKnowledgeable.svg";
import PersonalityEmpathetic from "../../icons/case-studies/rc/PersonalityEmpathetic.svg";
import PersonalityEncouraging from "../../icons/case-studies/rc/PersonalityEncouraging.svg";
import PersonalityBold from "../../icons/case-studies/rc/PersonalityBold.svg";
import ExecutionDescriptor from "../../icons/case-studies/rc/ExecutionDescriptor.svg";

// Results SVGS
import ResultsDescriptor from "../../icons/case-studies/rc/ResultsDescriptor.svg";
import Results17 from "../../icons/case-studies/rc/Results17.svg";
import Results95 from "../../icons/case-studies/rc/Results95.svg";
import ResultsDataVisual04Mobile from "../../icons/case-studies/rc/Robertson_Data-Visual_04_Mobile.svg";
import ResultsDataVisual04Desktop from "../../icons/case-studies/rc/Robertson_Data-Visual_04_Desktop.svg";
import ResultsDataPie12 from "../../icons/case-studies/rc/Results_Data-Visual_Pie-12.svg";
import ResultsDataPie40 from "../../icons/case-studies/rc/Results_Data-Visual_Pie-40.svg";
import ResultsDataPie15 from "../../icons/case-studies/rc/Results_Data-Visual_Pie-15.svg";
import ResultsClock from "../../icons/case-studies/rc/Results_clock.svg";
// import BirchwoodLogo from "../../icons/case-studies/rc/BirchwoodLogo.svg";

import { Helmet } from "react-helmet";

import "../../scss/swiper.scss";
import CaseStudyFooter from "../../components/case-study-general/CaseStudyFooter";

const RobertsonCollege = () => {
  const [hidenav, setNav] = useState(false);

  function setVisibility(isVisible) {
    // console.log(isVisible);
    if (isVisible) {
      setNav(true);
    } else {
      setNav(false);
    }
  }

  const data = useStaticQuery(query);
  // console.log(data);
  const {
    wpCaseStudy: {
      id,
      databaseId,
      featuredImage,
      nodeType,
      HeroSection,
      FeaturedImages,
      CaseStudy,
      author,
      seo,
      slug,
      status,
      title,
      uri,
    },
    ...images
  } = data;

  // Items to pass to CaseStudyNavigation component
  const navItems = [
    {
      id: "overview",
      target: "#overview",
      title: "Overview",
    },
    {
      id: "insights",
      target: "#insights",
      title: "Insights",
    },
    {
      id: "challenge",
      target: "#challenge",
      title: "Challenge",
    },
    {
      id: "strategy",
      target: "#strategy",
      title: "Strategy",
    },
    {
      id: "execution",
      target: "#execution",
      title: "Execution",
    },
    {
      id: "results",
      target: "#results",
      title: "Results",
    },
  ];

  return (
    <ContentProvider
      value={{
        id,
        databaseId,
        featuredImage,
        FeaturedImages,
        nodeType,
        HeroSection,
        CaseStudy,
        author,
        seo,
        slug,
        status,
        title,
        uri,
      }}
    >
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={seo.opengraphTitle || seo.title || `${title}`}
        meta={[
          {
            name: `robots`,
            content: `${seo.metaRobotsNoindex} ${seo.metaRobotsNofollow}`,
          },
          {
            name: `description`,
            content:
              seo.metaDesc ||
              `We repositioned Robertson within the post-secondary education industry while setting up the college to grow and scale. Read more to learn how we did it.`,
          },
          {
            name: `keywords`,
            content: seo.metaKeywords,
          },
          {
            property: `og:title`,
            content: seo.opengraphTitle || seo.title || `${title}`,
          },
          {
            property: `og:description`,
            content:
              seo.opengraphDescription ||
              seo.metaDesc ||
              `We repositioned Robertson within the post-secondary education industry while setting up the college to grow and scale. Read more to learn how we did it.`,
          },
          {
            property: `og:image`,
            content: seo.opengraphImage?.localFile?.publicURL,
          },
          {
            property: `og:type`,
            content: seo.opengraphType,
          },
          {
            property: `og:url`,
            content: seo.opengraphUrl,
          },
          {
            property: `og:site_name`,
            content: seo.opengraphSiteName,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `Brandish Agency`,
          },
          {
            name: `twitter:title`,
            content:
              seo.twitterTitle || seo.opengraphTitle || seo.title || `${title}`,
          },
          {
            name: `twitter:description`,
            content:
              seo.twitterDescription ||
              seo.opengraphDescription ||
              seo.metaDesc ||
              `We repositioned Robertson within the post-secondary education industry while setting up the college to grow and scale. Read more to learn how we did it.`,
          },
          {
            name: `twitter:image`,
            content:
              seo.twitterImage?.localFile?.publicURL ||
              seo.opengraphImage?.localFile?.publicURL,
          },
        ]}
      >
        <script type="application/ld+json">{seo?.schema?.raw}</script>
      </Helmet>
      <Layout>
        <Hero
          data={{
            ...HeroSection,
            ...FeaturedImages,
            CaseStudy,
            featuredImage: featuredImage?.node,
            logoAlignment: "right",
          }}
        />
        <CaseStudyOverview
          id="overview"
          OverviewDescriptor={<OverviewDescriptor />}
          OverviewBody={<OverviewBody />}
          OverviewLeads={<OverviewLeads />}
          OverviewCollege={<OverviewCollege />}
          OverviewCollegePurple={<OverviewCollegePurple />}
        />
        <CaseStudyInsights
          id="insights"
          InsightDescriptor={<InsightDescriptor />}
          InsightWorkforce={<InsightWorkforce />}
          InsightEducation={<InsightEducation />}
          Percent22={<Percent22 />}
          Percent50={<Percent50 />}
          Percent70={<Percent70 />}
          Percent75={<Percent75 />}
          LearnerNewLearner={<LearnerNewLearner />}
          LearnerReLearner={<LearnerReLearner />}
          LearnerCareerShifter={<LearnerCareerShifter />}
          InternationalStudents={<InternationalStudents />}
        />
        <CaseStudyChallenge
          id="challenge"
          ChallengeDescriptor={<ChallengeDescriptor />}
          ChallengeStrategy={<ChallengeStrategy />}
        />
        <CaseStudyStrategy
          id="strategy"
          StrategyDescriptor={<StrategyDescriptor />}
          StrategyGoalGetters={<StrategyGoalGetters />}
          PersonalityKnowledgeable={<PersonalityKnowledgeable />}
          PersonalityEmpathetic={<PersonalityEmpathetic />}
          PersonalityEncouraging={<PersonalityEncouraging />}
          PersonalityBold={<PersonalityBold />}
          {...images}
        />
        <CaseStudyExecution
          id="execution"
          ExecutionDescriptor={<ExecutionDescriptor />}
          {...images}
        />
        <CaseStudyResults
          id="results"
          ResultsDescriptor={<ResultsDescriptor />}
          Results17={<Results17 />}
          Results95={<Results95 />}
          ResultsDataVisual04Mobile={<ResultsDataVisual04Mobile />}
          ResultsDataVisual04Desktop={<ResultsDataVisual04Desktop />}
          ResultsDataPie12={<ResultsDataPie12 />}
          ResultsDataPie40={<ResultsDataPie40 />}
          ResultsDataPie15={<ResultsDataPie15 />}
          ResultsClock={<ResultsClock />}
          {...images}
        />
        <CaseStudyFooter
          setVisibility={setVisibility}
          caseStudy={CaseStudy.nextCaseStudy}
        />
        <CaseStudyNavigation navItems={navItems} hidenav={hidenav} />
      </Layout>
    </ContentProvider>
  );
};

const query = graphql`
  fragment File on File {
    name
    extension
    publicURL
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }

  fragment ImageFragment on WpMediaItem {
    databaseId
    altText
    localFile {
      extension
      publicURL
      relativePath
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }

  fragment CaseStudyFragmentRobertson on WpCase_study {
    databaseId
    title
    uri
    CaseStudy {
      fieldGroupName
      card {
        logo {
          ...ImageFragment
        }
        title
        description
        ctaText
        backgroundColor
        backgroundImageDesktop {
          ...ImageFragment
        }
        backgroundImageTablet {
          ...ImageFragment
        }
        backgroundImageMobile {
          ...ImageFragment
        }
      }
      hero {
        logo {
          ...ImageFragment
        }
      }
    }
    FeaturedImages {
      featuredImageMobile {
        ...ImageFragment
      }
      featuredImageTablet {
        ...ImageFragment
      }
      featuredImageDesktop {
        ...ImageFragment
      }
    }
    featuredImage {
      node {
        ...ImageFragment
      }
    }
  }

  {
    StrategyLogos: file(
      relativePath: { eq: "case-studies/rc/StrategyLogos.png" }
    ) {
      ...File
    }
    StrategyVideoThumbnail: file(
      relativePath: { eq: "case-studies/rc/StrategyVideoThumbnail.png" }
    ) {
      ...File
    }
    StrategyBillboard: file(
      relativePath: { eq: "case-studies/rc/StrategyBillboard.png" }
    ) {
      ...File
    }
    StrategyBusBillboard: file(
      relativePath: { eq: "case-studies/rc/StrategyBusBillboard.png" }
    ) {
      ...File
    }
    StrategyLetterhead: file(
      relativePath: { eq: "case-studies/rc/StrategyLetterhead.png" }
    ) {
      ...File
    }
    StrategyStationery: file(
      relativePath: { eq: "case-studies/rc/StrategyStationery.png" }
    ) {
      ...File
    }
    ExecutionWebsite: file(
      relativePath: { eq: "case-studies/rc/ExecutionWebsite.png" }
    ) {
      ...File
    }
    ExecutionDigital1: file(
      relativePath: { eq: "case-studies/rc/ExecutionDigital1.png" }
    ) {
      ...File
    }
    ExecutionDigital2: file(
      relativePath: { eq: "case-studies/rc/ExecutionDigital2.png" }
    ) {
      ...File
    }
    ResultsInstagram: file(
      relativePath: { eq: "case-studies/rc/ResultsInstagram.png" }
    ) {
      ...File
    }
    BirchwoodDesktop: file(
      relativePath: { eq: "case-studies/rc/BirchwoodDesktop.png" }
    ) {
      ...File
    }
    BirchwoodMobile: file(
      relativePath: { eq: "case-studies/rc/BirchwoodMobile.png" }
    ) {
      ...File
    }
    wpCaseStudy(databaseId: { eq: 749 }) {
      id
      databaseId
      nodeType
      slug
      status
      title
      uri
      seo {
        canonical
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphImage {
          ...ImageFragment
        }
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterImage {
          ...ImageFragment
        }
        twitterTitle
        schema {
          raw
        }
      }
      HeroSection {
        backgroundColor
        fieldGroupName
        hamburgerBackgroundColor
        heroType
        logoColor
        pageName
        subtitle
        title
      }
      PageCptFields {
        hideNavIcon
      }
      FeaturedImages {
        featuredImageMobile {
          ...ImageFragment
        }
        featuredImageTablet {
          ...ImageFragment
        }
        featuredImageDesktop {
          ...ImageFragment
        }
      }
      featuredImage {
        node {
          databaseId
          altText
          localFile {
            extension
            publicURL
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      CaseStudy {
        fieldGroupName
        card {
          logo {
            ...ImageFragment
          }
          title
          description
          ctaText
          backgroundColor
          backgroundImageDesktop {
            ...ImageFragment
          }
          backgroundImageTablet {
            ...ImageFragment
          }
          backgroundImageMobile {
            ...ImageFragment
          }
        }
        hero {
          logo {
            ...ImageFragment
          }
        }
        nextCaseStudy {
          ...CaseStudyFragmentRobertson
        }
      }
    }
  }
`;

export default RobertsonCollege;
