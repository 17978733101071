import React from "react";
import styled from "@emotion/styled";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";
import Timeline from "../../content-blocks/Timeline/Timeline";
// import { FaLongArrowAltRight } from "react-icons/fa";

const timelineData = [
  {
    year: "2018",
    dotColor: "#73ddd3",
    dotPulseColor: "#56c4c3",
    events: [
      {
        title: "Research & Assessment",
        text:
          "Robertson College engages Brandish to work with them. We start their brand audit and analysis of their customer journey and brand experience.",
      },
      {
        title: "Strategy",
        text:
          "Our work on a new Brand begins while the marketing environment is audited to identify challenges and opportunities.",
      },
      {
        title: "New Brand",
        text:
          "“The School of New Work” brand is approved and readied for market.",
      },
    ],
  },
  {
    year: "2019",
    dotColor: "#666cfd",
    dotPulseColor: "#5344e0",
    events: [
      {
        title: "Launch",
        text:
          "“The School of New Work” is launched in the market with a new website and a national multi-channel promotional campaign.",
      },
      {
        title: "Marketing Strategy",
        text:
          "A new marketing strategy is launched, transitioning a majority of marketing investment into digital channels from traditional channels.",
      },
      {
        title: "Internal Support",
        text:
          "Work begins on developing and improving internal capabilities to support the growth of the brand, marketing and experience strategies.",
      },
    ],
  },
  {
    year: "2020",
    dotColor: "#ffa3d3",
    dotPulseColor: "#ea90c4",
    events: [
      {
        title: "Growth & Scale",
        text:
          "After a fiscally successful year, more investment is directed toward performance marketing to expand the brand into new markets across Canada.",
      },
      {
        title: "Global Pandemic",
        text:
          "Covid-19 Response Strategy is developed to help Robertson navigate the crisis and maximize opportunity in the online education space.",
      },
      {
        title: "New Sub-brand Launched",
        text: "Robertson Online sub-brand is developed and launched in market.",
      },
    ],
  },
];

const ResultsComponent = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  /* Container Styles */
  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }
  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }
  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%; */
        max-width: 750px;
        margin-left: 23.189655172%;
      }
    }
  }
  .container-padding {
    padding: 4rem 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 7rem 12rem;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }

  .mobile-only {
    display: block;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
  }

  .results-section {
    &--lightPeach {
      background-color: ${props => props.theme.colors.lightPeach};
    }
    &--darkestPurple {
      background-color: ${props => props.theme.colors.darkestPurple};
    }
  }

  .mb-5 {
    margin-bottom: 5rem;
  }

  /* Black title of page */
  .black-title-h2 {
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 4rem;
    font-family: ${props => props.theme.fonts.medium};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-weight: 500;
      text-align: center;
      font-size: 6.5rem;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  /* Font Styling */
  .black-subtitle-h3 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 4.5rem;
    }
  }

  .white-subtitle-h3 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    color: ${props => props.theme.colors.offWhite};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 4.5rem;
    }
  }

  .purple-subtitle-h4 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${props => props.theme.colors.darkerPurple};
    margin-bottom: 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
    }
  }

  .gold-subtitle-h5 {
    font-family: ${props => props.theme.breakpoints.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.darkGold};
  }

  .black-text-paragraph {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2.5rem;
  }

  .gold-text-paragraph {
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      margin-bottom: 4rem;
    }
  }

  .white-text-paragraph {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offWhite};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
    }
  }

  /* Image Captions */
  .image-section {
    margin-bottom: 4rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 6rem;
    }
  }
  .image-caption {
    display: flex;
    align-items: center;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 4rem;
    }

    .research-pie-graphs {
      width: 18rem;
      height: 18rem;
    }

    p {
      text-align: left;
      /* width: 60%; */
      max-width: 170px;
      /* padding: 0 3rem; */
      margin: 0;
      margin-left: 3rem;
      font-family: ${props => props.theme.fonts.italic};
      font-size: 1.5rem;
      line-height: 25px;
      color: ${props => props.theme.colors.darkTeal};

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.5rem;
        line-height: 39px;
        margin-left: 4rem;
        letter-spacing: 0.75px;
        max-width: 429px;
      }
    }
  }

  /*
  .teal-body-text {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    margin-bottom: 4rem;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 39px;
    }
  } */

  /* .marketing-result-box {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 7rem;
    }
  } */

  /* .teal-caption {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 1.5rem;
    line-height: 25px;
    margin-left: 4%;
    width: 49.47089947%;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      letter-spacing: 0.75px;
    }
  }

  .black-title {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 2.5rem;
    line-height: 32px;
    color: ${props => props.theme.colors.offBlack};
    margin-top: 4rem;
    margin-bottom: 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }

  .gold-caption {
    font-size: 1.5rem;
    line-height: 25px;
    text-align: center;
    margin-bottom: 3.5rem;
    color: ${props => props.theme.colors.darkGold};
  } */
`;

// const MarketingResultImage = styled(StaticImage)`
//   width: 26.455026455%;
//   height: auto;
// `;

const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyResults = props => {
  return (
    <ResultsComponent id={props.id || "results"}>
      <div className="results-section results-section--lightPeach container-padding">
        <CaseStudyContainer>
          <h2 className="black-title-h2">Results</h2>
          <TitleLine />
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor title="outcome" color="#D38B5C" alignment="center" />
              <DescriptorImage image={props.ResultsDescriptor} />
            </div>
            <div className="contentContainer">
              <h3 className="black-subtitle-h3">What our work achieved</h3>
              <h4 className="purple-subtitle-h4">Transformation:</h4>
              <p className="black-text-paragraph">
                Robertson transformed into a true customer centric business
                which built intrinsic value throughout the entire organization,
                across all departments both internally and externally.
              </p>
              <h4 className="purple-subtitle-h4">Performance:</h4>
              <p className="black-text-paragraph">
                The entire organization is focused on new positioning and the
                new value proposition. This collective focus has led to better
                fiscal performance (more YoY revenue), and in turn better
                positioned the organization to more successfully capitalize on
                opportunities including the online market. Robertson’s new brand
                set the college up for success online because it was built with
                their audience’s needs and motivations at the forefront.
              </p>
              <h4 className="purple-subtitle-h4">Modernization:</h4>
              <p className="black-text-paragraph">
                We shifted the marketing environment to focus more on digital
                and we placed less reliance on traditional advertising. This
                resulted in stronger and more effective marketing performance.
              </p>
              <h4 className="purple-subtitle-h4">Quality Lead Generation:</h4>
              <p className="black-text-paragraph">
                As a result of higher performing marketing, the quality of
                inbound lead generation —across all channels —has improved
                substantially, which has in turn improved sales performance
                metrics resulting in higher enrollment and revenue. This was the
                initial problem Robertson approached us with, and this is a
                testament that this particular problem could not be solved
                without addressing their brand and positioning.
              </p>
            </div>
          </div>
        </CaseStudyContainer>
      </div>
      <div className="results-section results-section--darkestPurple container-padding">
        <CaseStudyContainer>
          <div className="contentContainer--single">
            <h2 className="white-subtitle-h3">Robertson Timeline</h2>
          </div>
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor
                title="Our Journey"
                color="#D38B5C"
                alignment="center"
              />
              <DescriptorImage image={props.ResultsClock} />
            </div>
            <div className="contentContainer">
              <p className="gold-text-paragraph">
                Our partnership with Robertson began in 2018. This timeline
                marks our work’s major milestones and how we repositioned
                Robertson within the post-secondary education industry while
                setting up the college to grow and scale.
              </p>
              <Timeline years={timelineData} />
            </div>
          </div>
        </CaseStudyContainer>
      </div>
      <div className="results-section results-section--lightPeach container-padding">
        <CaseStudyContainer>
          <div className="sectionWrapper">
            <div className="contentContainer--single">
              <h3 className="black-subtitle-h3">Performance</h3>
              <h5 className="gold-subtitle-h5">Website</h5>
              <p className="black-text-paragraph">
                Website traffic in new markets increased by 220% from 2019 to
                2020.
              </p>
              <Image
                image={props.ResultsDataVisual04Mobile}
                className="mb-5 mobile-only"
              />
              <Image
                image={props.ResultsDataVisual04Desktop}
                className="mb-5 desktop-only"
              />
              <h5 className="gold-subtitle-h5">Leads</h5>
              <div className="image-section">
                <div className="image-caption">
                  <Image
                    className="research-pie-graphs"
                    image={props.ResultsDataPie12}
                  />
                  <p>12% Increase in total lead volume YoY in 2020.</p>
                </div>
                <div className="image-caption">
                  <Image
                    className="research-pie-graphs"
                    image={props.ResultsDataPie40}
                  />
                  <p>40% increase in total online lead volume YoY in 2020.</p>
                </div>
              </div>
              <h5 className="gold-subtitle-h5">Revenue</h5>
              <div className="image-caption">
                <Image
                  className="research-pie-graphs"
                  image={props.ResultsDataPie15}
                />
                <p>15% (approx.) increase in online education revenue.</p>
              </div>
            </div>
          </div>
        </CaseStudyContainer>
      </div>
    </ResultsComponent>
  );
};

export default CaseStudyResults;
