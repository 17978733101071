import React from "react";
import styled from "@emotion/styled";
// import { css } from "@emotion/react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import { PaginationBullet } from "../PaginationBullet";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const ExecutionComponent = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 4rem 1.5rem;
  font-family: ${props => props.theme.fonts.regular};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }
  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }
  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%; */
        margin-left: 23.189655172%;
        max-width: 750px;
      }
    }
  }

  .container-padding {
    padding: 4rem 1.5rem;
  }

  .execution-title {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 4rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 6.5rem;
      line-height: 80px;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .execution-secondary-title {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    margin-bottom: 3rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
    }
  }

  .gold-text-content {
    font-size: 2.3rem;
    line-height: 37px;
    margin-bottom: 3rem;
    color: ${props => props.theme.colors.darkGold};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }
  }

  .black-body-text {
    font-size: 1.8rem;
    line-height: 29px;
    margin-bottom: 2.5rem;
    color: ${props => props.theme.colors.offsetBlack};
  }

  .execution-subtitle {
    font-family: ${props => props.theme.fonts.medium};

    font-size: 2.5rem;
    line-height: 32px;
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 3rem;
    }
  }

  .execution-caption {
    font-size: 1.5rem;
    line-height: 25px;
    text-align: center;
    margin: 2rem 0;
    color: ${props => props.theme.colors.darkGold};
  }

  .execution-website-image {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 1265px !important;
      margin: 0 auto;
    }
  }
`;

const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

SwiperCore.use([Pagination]);

const CaseStudyExecution = props => {
  return (
    <ExecutionComponent
      className="container-padding"
      id={props.id || "execution"}
    >
      <CaseStudyContainer>
        <h2 className="execution-title">Execution</h2>
        <TitleLine />
        <div className="execution-content  sectionWrapper">
          <div className="leftWrapper">
            <Descriptor
              title="creating the <br> experience"
              color="#D38B5C"
              alignment="center"
            />
            <DescriptorImage image={props.ExecutionDescriptor} />
          </div>
          <div className="contentContainer">
            <p className="gold-text-content">
              Our strategy focused on connecting Robertson with potential
              students in a meaningful and valuable way. In order to do so, we
              needed to understand what potential students look for when
              choosing a post-secondary institution to attend. We mapped out and
              defined the potential students’ customer journey
            </p>
            <p className="black-body-text">
              Next, we considered how Robertson could create an impactful
              experience during each phase of this journey.
            </p>
            <p className="black-body-text">
              Previously, Robertson focused their marketing on targeting
              potential students in the Awareness Phase. Their marketing was
              focused on “traditional” channels including print, radio and TV
              that can’t be accurately tracked or measured.
            </p>
            <p className="black-body-text">
              We knew that potential students were researching their
              post-secondary education options online. By shifting Robertson’s
              marketing approach digitally, we could more effectively
              communicate with our target audience and track our engagements.
            </p>
            <p className="black-body-text">
              Robertson was also missing opportunities to nurture potential
              students in other phases of the customer journey. By creating
              touchpoints in each phase of the journey, Robertson would have
              more opportunities to convert potential students into enrolled
              students and better understand what students are looking for. And
              through this analysis, we discovered an opportunity to expand
              Robertson’s presence nationally by developing their online
              programs. This would raise Robertson’s profile in areas where
              campuses didn’t exist and in turn, increase Robertson’s market
              share beyond western Canada.
            </p>
            <h3 className="execution-secondary-title">
              Moving from a traditional to a digital approach
            </h3>
            <p className="gold-text-content">
              The digital world created a new customer --a customer that expects
              a brand to deliver a consistent experience across all platforms.
              We needed to create a unified experience for Robertson across all
              of their communication touchpoints.
            </p>
            <p className="black-body-text">
              We needed to create a unified experience for Robertson across all
              of their communication touchpoints.
            </p>
            <h3 className="execution-subtitle">Future growth</h3>
            <p className="black-body-text">
              Through our research, we identified there was significant room for
              growth in online search with data indicating there were high
              volumes of education related search queries. These were
              opportunities for Robertson to capture visibility at a local,
              regional, and national level for both direct and research focused
              search phrases.
            </p>
            <h3 className="execution-subtitle">
              Our digital strategy included:
            </h3>
            <h4 className="gold-text-content">Organic Search Visibility:</h4>
            <p className="black-body-text">
              We built out opportunities to connect with Robertson’s audience
              organically by creating useful and engaging content such as blog
              posts and video content. This content contributed to the overall
              visibility of the college while providing resources that
              Robertson’s audience was already searching for online.
            </p>
            <h4 className="gold-text-content">Optimize Paid Digital Ads:</h4>
            <p className="black-body-text">
              We assessed Robertson’s existing paid digital ads and re-evaluated
              their approach focusing on best practices for ads. We created
              brand consistency across platforms and built tools to use for
              email marketing, automation and personalization more
              strategically.
            </p>
            <h4 className="gold-text-content">
              Build infrastructure and use data strategically:
            </h4>
            <p className="black-body-text">
              With a digital marketing focus, we were able to track engagements
              more accurately. This allowed us to tailor our marketing
              strategies and develop content specifically targeting audiences in
              different phases of the customer journey.
            </p>
          </div>
        </div>
        <div className="brand-carousel-container">
          <Swiper
            loop={true}
            pagination={{
              clickable: true,
              renderBullet: PaginationBullet,
              bulletClass: "carousel-bullet",
              bulletActiveClass: "carousel-bullet--active",
            }}
          >
            <SwiperSlide>
              <Image image={props.ExecutionDigital1} />
            </SwiperSlide>
            <SwiperSlide>
              <Image image={props.ExecutionDigital2} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="contentContainer--single">
          <p className="execution-caption">Robertson Digital assets</p>
          <p className="gold-text-content">Website:</p>
          <p className="black-body-text">
            With a new brand strategy and identity coupled with a deep
            understanding of the customer journey, we built a new website for
            Robertson.
          </p>
          <p className="gold-text-content">
            The website focused on the student experience by highlighting the
            value of Robertson’s programs rather than a list of their services.
          </p>
        </div>
        <Image
          image={props.ExecutionWebsite}
          className="execution-website-image"
        />
        <p className="execution-caption">Robertson website</p>
      </CaseStudyContainer>
    </ExecutionComponent>
  );
};

export default CaseStudyExecution;
