import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const OverviewComponent = styled.div`
  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-right: 12.586206896%;
        margin-left: 23.189655172%;
        max-width: 750px;
      }
    }
  }

  .gold-paragraph {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 2.3rem;
    line-height: 37px;
    color: ${props => props.theme.colors.darkGold};
    margin-top: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }
  }
`;

const OverviewRedefineSection = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 3rem 1.5rem 5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  h1 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-weight: 500;
      width: 100%;
      font-size: 6.5rem;
      line-height: 80px;
      text-align: center;
      margin: 0 auto 2rem;
    }
  }

  .redefine-roles {
    font-family: ${props => props.theme.fonts.light};
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 32px;
    color: ${props => props.theme.colors.offBlack};

    span {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: inline-block;
      margin-right: 5rem;
      padding-top: 1.25rem;
    }
  }

  .gold-paragraph {
  }

  p {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin: 3rem 0;
  }
`;

const OverviewLeadsSection = styled.div`
  background-color: ${props => props.theme.colors.darkestPurple};
  padding: 4rem 1.5rem 7.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  h2 {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 4rem;
    line-height: 50px;
    color: ${props => props.theme.colors.lightPeach};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-left: 23.189655172%;
      width: 68%;
    }
  }

  .image-caption-box {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
      align-items: center;
    }
  }

  .teal-paragraph {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-left: 4rem;
      font-size: 2.5rem;
      line-height: 39px;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.lightPeach};
    margin: 3rem 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
      font-weight: 300;
    }
  }

  .marketing-leads-image {
    width: 60%;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 50%;
    }
  }
`;

const OverviewPotentialSection = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 4rem 1.5rem;

  .leftWrapper {
    margin-top: 17.5px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .potential-title {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 4rem;
    line-height: 50px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
      /* width: 56%; */
      margin-left: 23.189655172%;
      font-size: 4.8rem;
      line-height: 60px;
    }
  }

  .teal-paragraph {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      letter-spacing: 0.75px;
      width: 71%;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin: 3rem 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
      font-weight: 300;
    }
  }
`;
const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyOverview = props => {
  // console.log(props);
  return (
    <OverviewComponent id={props.id || "overview"}>
      <OverviewRedefineSection>
        <CaseStudyContainer>
          <h1>Redefining the role of the private career college</h1>
          <TitleLine />
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor
                title="overview"
                color="#D38B5C"
                textAlign="center"
                alignment="center"
              />

              <DescriptorImage image={props.OverviewDescriptor} />
            </div>
            <div className="contentContainer">
              <h3 className="redefine-roles">
                <span>Partner:</span> Robertson College
              </h3>
              <h3 className="redefine-roles">
                <span>Our Role:</span> Brand Strategy and marketing
              </h3>
              <p className="gold-paragraph">
                For over 100 years, Roberston has offered career-focused
                programs in western Canada. We first partnered with Robertson
                College in 2018 when the college reached out for help with their
                marketing capabilities.
              </p>
              <p>
                In this Case Study, we’ll examine how a private career college
                (PCC) repositioned their business to shift their focus back to
                the people who mattered most —students. And by doing so, how
                Robertson differentiated their college from competitors, gained
                market share and scaled their growth.
              </p>
              <Image
                css={css`
                  margin: auto;
                `}
                image={props.OverviewBody}
              />
            </div>
          </div>
        </CaseStudyContainer>
      </OverviewRedefineSection>
      <OverviewLeadsSection>
        <CaseStudyContainer>
          <h2>Marketing wasn’t generating leads.</h2>
          <div className="contentContainer--single">
            <p>
              The college initially approached our agency to increase their lead
              generation which would drive their enrollments and attract new
              students to their college. Even though they were spending more and
              more on their marketing efforts, they were generating fewer and
              fewer leads.
            </p>
            <div className="image-caption-box">
              <Image
                image={props.OverviewLeads}
                className="marketing-leads-image"
              />
              <p className="teal-paragraph">
                Leads dropping as <br />
                marketing spend increases.
              </p>
            </div>
            <p>
              After evaluating the situation, we discovered that the problem was
              not a simple matter of their budget and marketing spend. In order
              to effectively reach Robertson College’s goals and target
              audiences, we needed to understand the post-secondary industry
              along with the college’s current position and perception.
            </p>
          </div>
        </CaseStudyContainer>
      </OverviewLeadsSection>
      <OverviewPotentialSection>
        <CaseStudyContainer>
          <h3 className="potential-title">
            A Private Career College With Hidden Potential.
          </h3>
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor
                title="Robertson <br/> College"
                color="#D38B5C"
                textAlign="center"
                alignment="center"
              />
              <DescriptorImage image={props.OverviewCollege} />
            </div>
            <div className="contentContainer">
              <p>
                Robertson College is a private Canadian career training
                institution with campus locations in Winnipeg, Brandon,
                Edmonton, Calgary and online. The college offers diploma
                programs in health, business, technology and continuing
                education.
              </p>
              <Image image={props.OverviewCollegePurple} />
              <p className="teal-paragraph">
                There are over 1,300 private career colleges in Canada with over
                170,000 learners enrolled annually.
              </p>
              <p>
                Our work began with a deep understanding of the people Robertson
                was trying to connect with, students. This would help Robertson
                reposition their brand to address their issues with
                communication and engagement.
              </p>
            </div>
          </div>
        </CaseStudyContainer>
      </OverviewPotentialSection>
    </OverviewComponent>
  );
};

export default CaseStudyOverview;
